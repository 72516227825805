// Custom SCSS ---------------------------------------------------------------------------------------------------------
require('./styles/app.scss');


import './bootstrap';
import './content-slider';
import './fullpage-slider';

import {library, dom} from './fontawesome/js-packages/@fortawesome/fontawesome-svg-core'
import {far} from './fontawesome/js-packages/@fortawesome/pro-regular-svg-icons'
import {fal} from './fontawesome/js-packages/@fortawesome/pro-light-svg-icons'
import {fab} from './fontawesome/js-packages/@fortawesome/pro-brands-svg-icons'
import Masonry from 'masonry-layout';

require('bootstrap');
require('bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css');
require('bootstrap-datepicker');

// FontAwesome ---------------------------------------------------------------------------------------------------------
// We are only using the user-astronaut icon
library.add(far, fal, fab)

// Replace any existing <i> tags with <svg> and set up a MutationObserver to
// continue doing this as the DOM changes.
dom.watch()

// Make jQuery globally available --------------------------------------------------------------------------------------
$ = require('jquery');
require('fancybox')($);
global.$ = global.jQuery = $;

$('.datepicker').datepicker({
    format: 'dd.mm.yyyy',
});

// Offcanvas Menu ------------------------------------------------------------------------------------------------------
$('.js-offcanvas-toggle').on('click', function (event) {
    const $offcanvas = $('.offcanvas-left');

    $offcanvas.toggleClass('visible');
    $('body').toggleClass('noscroll');
});

// Main Category Pages in Sidebar-Navigation ---------------------------------------------------------------------------
$('.nav-ajax-link').on('click', function (event) {
    event.preventDefault();
    let url = $(this).data('href');
    $('#nav-content').load(url);
});

// Fix Modal Windows ---------------------------------------------------------------------------------------------------
$('.modal').appendTo('body');

// Scroll-Top ----------------------------------------------------------------------------------------------------------
$('#js-btn-scroll-top').on('click', function () {
    $('body,html').animate({
        scrollTop: 0
    }, 800);
});

// Ainmate Images in, when appearing in viewport

// Helper function to check if an element is at least 100 pixels in the viewport on the y-axis
function isAtLeast100PixelsInViewport(element) {
    var rect = element.getBoundingClientRect();
    return rect.top <= (window.innerHeight || document.documentElement.clientHeight) - 100 && rect.bottom >= 100;
}

// Function to handle the scrolling event
function handleScroll() {
    var elements = document.getElementsByClassName("animate-in");
    for (var i = 0; i < elements.length; i++) {
        if (isAtLeast100PixelsInViewport(elements[i])) {
            elements[i].classList.add("visible");
        } else {
            // elements[i].classList.remove("visible");
        }
    }
}

// Function to execute handleScroll at page load
function handlePageLoad() {
    handleScroll();
}

// Attach scroll event listener to the window
window.addEventListener("scroll", handleScroll);
// Execute handleScroll at page load
window.addEventListener("load", handlePageLoad);


// Scroll Spy ----------------------------------------------------------------------------------------------------------
let last_known_scroll_position = 0;
let ticking = false;
let screen_width = window.innerWidth;

function toggleTopBar(scroll_pos) {
    screen_width = window.innerWidth;
    const header = $('header');
    let inverted = header.data('inverted');

    if (screen_width >= 991) {
        if (scroll_pos > 20) {
            header.addClass('small shadow bg-white');
            $('#topbar a').removeClass('pt-xl-3 pb-xl-3');
            $('#topbar a').addClass('pt-xl-0 pb-xl-0');

            if (inverted === 1) {
                header.removeClass('invert');
            }

        } else {
            header.removeClass('small shadow bg-white');
            $('#topbar a').removeClass('pt-xl-0 pb-xl-0');
            $('#topbar a').addClass('pt-xl-3 pb-xl-3');
            $('#topbar a').addClass('pt-xl-3 pb-xl-3');

            if (inverted === 1) {
                header.addClass('invert');
            }
        }
    } else {
        if (scroll_pos > 20) {
            header.addClass('small shadow bg-white');
            if (inverted === 1) {
                header.removeClass('invert');
                $('#mini-logo').attr('src', '/build/website/static/images/logo.png');
            } else {
                $('#mini-logo').attr('src', '/build/website/static/images/logo.png');
            }
        } else {
            header.removeClass('small shadow bg-white');

            if (inverted === 1) {
                header.addClass('invert');
                $('#mini-logo').attr('src', '/build/website/static/images/LOGO_EINHORN_bw-invert.svg');
            } else {
                $('#mini-logo').attr('src', '/build/website/static/images/logo.png');
            }
        }
    }
}


window.addEventListener('scroll', function (e) {
    last_known_scroll_position = window.scrollY;

    if (!ticking) {
        window.requestAnimationFrame(function () {
            toggleTopBar(last_known_scroll_position);
            ticking = false;
        });
        ticking = true;
    }
});

$(document).ready(function () {
    let header = $('header');
    let inverted = header.data('inverted');

    if (inverted === 1) {
        $('#mini-logo').attr('src', '/build/website/static/images/LOGO_EINHORN_bw-invert.svg');
    }

    $(document).on('change', '.mobile-filter .filter-form-dropdown', function() {
        console.log("sasdsd");
        const searchParams = new URLSearchParams();
        $(".mobile-filter .filter-form-dropdown").each(function (index, dropdown) {
            searchParams.set($(dropdown).attr('name'), $(dropdown).val());
        });
        console.log(searchParams.toString());


        $(".mobile-filter").css("opacity", "0.5");
        $.ajax({
            type: 'GET',
            url: '/de/searchget-filters' + '?' + searchParams.toString(),
            success: function (response) {

                $("#filter-result").html(response);
                $(".mobile-filter").css("opacity", "1.0");
            },
            error: function (response) {
            }
        });


    });

    $(".desktop-filter .filter-form-dropdown").on("change", function () {
        $("#filter-form").submit();
    });

    $(".filter-tag").on("click", function () {
        var id = $(this).attr('id');
        const filterName = $(this).attr('id').split('-')[3];
        $("#" + filterName).val(null)
        $("#filter-form").submit();
    });

    $('#js-btn-search-load-more').on('click', function () {

        let self = $('#js-btn-search-load-more');
        let url = new URL(window.location.href);
        let page = self.data('page') + 1;
        self.data('page', page)
        const searchParams = new URLSearchParams(url.search);

        searchParams.set('page', page);

        $.ajax({
            type: 'GET',
            url: '/de/search/next' + '?' + searchParams.toString(),
            success: function (response) {


                $("#product-result").append(response.html);

                if (!response['collection']['next']) {
                    $('#js-btn-search-load-more').remove();

                } else {
                    $('#js-btn-search-load-more').appendTo('#product-result');
                }


            },
            error: function (response) {


            }
        });

    });

});

$('.carousel-item').on('click', function () {
    let self = $(this);
    let target = self.data('url');

    if (target) {
        window.location.href = target;
    }
});

window.addEventListener('load', function () {
    // Wählen Sie das Element aus, das Sie zu einer Masonry-Gitterlayout-Anordnung machen möchten
    var grid = document.querySelector('.grid');

    // Initialisieren Sie Masonry
    var masonry = new Masonry(grid, {
        // Konfigurieren Sie hier Ihre Optionen, falls gewünscht
        itemSelector: '.masonry-grid-item',
        columnWidth: 100,
        gutter: '.gutter-sizer',
        percentPosition: true

        // Weitere Optionen können in der Masonry-Dokumentation gefunden werden: https://masonry.desandro.com/options.html
    });

});




