import 'slick-carousel/slick/slick.js';

const ContentSlider = (function() {

    function init() {
        const sliderContainer = $('.slick-content-slider');

        if (sliderContainer.length <= 0) {
            return;
        }

        buildSlider(sliderContainer);
    }

    function buildSlider(sliderContainer) {

        sliderContainer.on('init', function() {
            // position arrows after init
            sliderContainer.siblings().find('button').css('top', -(sliderContainer.outerHeight() / 2) - 40);
        });

        sliderContainer.on('breakpoint', function() {
            // position arrows after breakpoint switch
            sliderContainer.siblings().find('button').css('top', -(sliderContainer.outerHeight() / 2) - 40);
        });

        sliderContainer.slick({
            dots: true,
            arrows: true,
            infinite: true,
            prevArrow: '<button class="js-slick-prev slick-arrow" aria-label="Previous" type="button"><i class="fal fa-chevron-left"></i></button>',
            nextArrow: '<button class="js-slick-next slick-arrow" aria-label="Next" type="button"><i class="fal fa-chevron-right"></i></button>',
            appendArrows: '.item__content-slider .arrow-container',
            speed: 300,
            slidesToShow: 1,
            centerMode: true,
        });
    }

    return init;
}());

$(document).ready(function() {
    new ContentSlider('init');
});
